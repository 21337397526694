import { Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../commons/components/header/Header';
import Footer from '../../commons/components/footer/Footer';
import useSubmodules from '../../commons/hooks/modules';
import Spinner from '../../commons/components/spinner/Spinner';
import { IUseParams } from '../../commons/interfaces/modules';
import './details.scss';

function Details() {
    const { id } = useParams() as IUseParams;
    const { idSubmodule, view } = useSubmodules({
        idSubmodule: id,
    });
    const ViewComponent = lazy(
        () => import('./detailView' + id + '/DetailView' + id)
    );

    return (
        <div className="ContentFull">
            <Header></Header>
            <div className="Content">
                {idSubmodule && view && (
                    <Suspense fallback={<Spinner />}>
                        <ViewComponent />
                    </Suspense>
                )}
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Details;

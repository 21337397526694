import { useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import HttpAxios from '../../../commons/hooks/http';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Disclosure, Transition } from '@headlessui/react';
import { useUserMe } from '../../../commons/hooks/userme';
import { AppContext } from '../../../commons/context/App-context';
import { ESelectCustom, ESelectTheme } from '../../../commons/statics/controls';
import {
    IDashboardChecklist,
    IPayload53,
} from '../../../commons/interfaces/modules';
import IconArrowDown from '../../../images/arrow-down.svg';
import { ITiendas } from '../../../commons/interfaces/user';
import Card from '../../../commons/components/card/Card';
import enviroment from '../../../enviroments/enviroment';
import { Errors } from '../../../commons/constants/submodules';
import es from 'date-fns/locale/es';
import './view53.scss';

registerLocale('es', es);

let memoParams: any = {};

function View53() {
    const {
        isLoading,
        changeIsLoading,
        changeUrlBackTop,
        changePayload53,
        payload53,
    } = useContext(AppContext);
    const [noData, setNoData] = useState<boolean>(false);
    const defaultOption = {
        value: 0,
        label: 'TODOS',
    };
    const [msgError, setMsgError] = useState<string>('');
    const [useMe, setUseMe] = useUserMe();
    const [name, setName] = useState<string>('');
    const [stores, setStores] = useState<Array<ITiendas> | null>(null);
    const [checklists, setChecklists] = useState([]);
    const [lists, setLists] = useState<any>(defaultOption);
    const [store, setStore] = useState(defaultOption);
    const [dataFromSearh, setDataFromSearh] = useState<
        Array<IDashboardChecklist>
    >([]);

    const [initLoading, setInitLoading] = useState<boolean>(false);
    const selectStyles = ESelectCustom;
    const selectTheme = ESelectTheme;
    const [startDate, setStartDate] = useState(new Date());
    const btnSearchRef = useRef<any>(null);
    let timer: any;
    const getItems = (items: any, hasDefault?: boolean): any => {
        let temp: any = hasDefault ? [] : [defaultOption];

        items?.forEach((s: any) => {
            temp.push({
                value: s.id,
                label: s.descripcion,
            });
        });

        return temp;
    };

    const onChange = (date: any) => {
        setStartDate(date);
    };

    const getTypes = () => {
        changeIsLoading(true);
        HttpAxios.post('/checklists', {
            idmodulo: 11,
            todos: true,
            identidad: 0,
        })
            .then((res: any) => {
                const items: Array<any> = res?.data?.response || [];
                const options = getItems(items, true);
                setChecklists(options);
                if (payload53) {
                    const elem = options.filter(
                        (el: any) => el.value === payload53?.idcheck
                    );
                    if (elem) {
                        setLists(elem[0]);
                    }
                    searchByBack();
                    changePayload53(null);
                } else {
                    changeIsLoading(false);
                    changePayload53(null);
                }
            })
            .catch((err) => {
                changeIsLoading(false);
                changePayload53(null);
            });
    };

    const search = () => {
        changeIsLoading(true);
        setInitLoading(true);
        setMsgError('');
        // timer && clearInterval(timer);
        // timer = undefined;
        const date = dayjs(startDate).format(enviroment.search.formatDate);
        setDataFromSearh([]);
        const payload: IPayload53 = {
            idtienda: store?.value || 0,
            idcheck: lists?.value || 0,
            fecha_ini: date,
            fecha_fin: date,
            idmodulo: 11,
            flagcerrado: false,
        };

        memoParams = payload;

        HttpAxios.post('/checklists/dashboard', payload)
            .then((res: any) => {
                const data = res?.data?.response || [];
                if (data.length) {
                    setNoData(false);
                    setDataFromSearh(data);
                    // updateSearch();
                } else {
                    setNoData(true);
                    setDataFromSearh([]);
                }

                changeIsLoading(false);
                setInitLoading(false);
            })
            .catch(() => {
                setMsgError(Errors.generic);
                changeIsLoading(false);
                setInitLoading(false);
            });
    };

    const onChangeChecklists = (evt: any) => {
        setLists(evt);
    };

    const onChangeStores = (evt: any) => {
        setStore(evt);
    };

    const updateSearch = (reset?: boolean) => {
        timer =
            !timer &&
            setInterval(() => {
                search();
                timer = undefined;
            }, 5000);

        reset && clearInterval(timer);
    };

    const searchByBack = () => {
        if (payload53 && btnSearchRef) {
            btnSearchRef.current.click();
        }
    };

    const initBackValues = (stores: Array<ITiendas> | null) => {
        if (payload53) {
            const { fecha_ini } = payload53;
            const y: number = Number(fecha_ini.substring(0, 4));
            const m: number = Number(fecha_ini.substring(4, 6)) - 1;
            const d: number = Number(fecha_ini.substring(6, 8));
            setStartDate(new Date(y, m, d));

            const elem: any = (stores || []).filter(
                (el: any) => el.id === payload53?.idtienda
            );
            if (elem) {
                const el = getItems(elem);
                setStore(el.length === 1 ? el[0] : el[1]);
            }
        }
    };

    useEffect(() => {
        const name = useMe?.info?.user?.nombre || '';
        setName(name);
        const stores: Array<ITiendas> | null = useMe?.tiendas || null;
        setStores(stores);
        // clearInterval(timer);
        !payload53 && search();
        initBackValues(stores);
        getTypes();
        changeUrlBackTop('/submodulo/53');
        // return () => clearInterval(timer);
    }, []);

    return (
        <>
            <div className="View53Search">
                <div className="row-4">
                    <div className="col">
                        <label className="label">Tienda</label>
                        <Select
                            id="store"
                            name="store"
                            styles={selectStyles}
                            theme={selectTheme}
                            value={store}
                            onChange={(evt) => onChangeStores(evt)}
                            placeholder="Selecciona una tienda"
                            options={getItems(stores)}
                        />
                    </div>
                    <div className="col">
                        <label className="label">Tipo CheckList</label>
                        <Select
                            id="lists"
                            name="lists"
                            styles={selectStyles}
                            theme={selectTheme}
                            value={lists}
                            onChange={(evt) => onChangeChecklists(evt)}
                            placeholder="Selecciona un tipo"
                            options={checklists}
                        />
                    </div>
                    <div className="col">
                        <label className="label">Fecha</label>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            locale="es"
                            className="input"
                            dateFormat="dd/MM/yyyy"
                            disabled={false}
                            placeholderText="Selecione una de fecha"
                            maxDate={new Date()}
                        />
                    </div>
                    <div className="col">
                        <label className="label empty">&nbsp;</label>
                        <button
                            type="button"
                            onClick={() => search()}
                            className="button buttonFull"
                            ref={btnSearchRef}
                        >
                            Buscar
                        </button>
                    </div>
                </div>
            </div>

            <div className="View53Body">
                {!initLoading && (
                    <>
                        {noData || msgError ? (
                            <div
                                className={classnames({
                                    noData: noData,
                                    msgError: msgError,
                                })}
                            >
                                {msgError
                                    ? msgError
                                    : 'No se encontró resultado en la búsqueda'}
                            </div>
                        ) : (
                            <>
                                {dataFromSearh.map((d, ind) => (
                                    <Disclosure
                                        as="div"
                                        className="Disclosure"
                                        defaultOpen={true}
                                        key={'block-' + ind}
                                    >
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button
                                                    as="div"
                                                    className="DisclosureButton"
                                                >
                                                    <span className="DisclosureButtonText">
                                                        {d?.Grupo}
                                                    </span>
                                                    <span className="DisclosureButtonImg">
                                                        <img
                                                            className={classnames(
                                                                {
                                                                    open: open,
                                                                }
                                                            )}
                                                            src={IconArrowDown}
                                                            alt=""
                                                        />
                                                    </span>
                                                </Disclosure.Button>
                                                {open && (
                                                    <Transition
                                                        show={open}
                                                        enter="DisclosureEnter"
                                                        enterFrom="DisclosureEnterFrom"
                                                        enterTo="DisclosureEnterTo"
                                                        leave="DisclosureLeave"
                                                        leaveFrom="DisclosureLeaveFrom"
                                                        leaveTo="DisclosureLeaveTo"
                                                    >
                                                        <Disclosure.Panel
                                                            as="div"
                                                            className="DisclosurePanel"
                                                        >
                                                            <div className="View53BodyCards">
                                                                {d?.Tiendas &&
                                                                    d.Tiendas.map(
                                                                        (
                                                                            t,
                                                                            i
                                                                        ) => (
                                                                            <Card
                                                                                idModule={
                                                                                    53
                                                                                }
                                                                                data={
                                                                                    t
                                                                                }
                                                                                key={
                                                                                    'card-' +
                                                                                    i
                                                                                }
                                                                                onClickCard={
                                                                                    changePayload53
                                                                                }
                                                                                payload={
                                                                                    memoParams
                                                                                }
                                                                            ></Card>
                                                                        )
                                                                    )}
                                                            </div>
                                                        </Disclosure.Panel>
                                                    </Transition>
                                                )}
                                            </>
                                        )}
                                    </Disclosure>
                                ))}
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default View53;

import { useContext, useEffect } from 'react';
import { AppContext } from '../../../commons/context/App-context';
import classnames from 'classnames';
import './snackbar.scss';

function Snackbar() {
    const { snackBar, changeSnackBar } = useContext(AppContext);
    const isError = (s: string = ''): boolean => {
        return s.toLocaleLowerCase().indexOf('error') > -1;
    };
    const isSuccess = (s: string = ''): boolean => {
        return s.toLocaleLowerCase().indexOf('correctamente') > -1;
    };

    useEffect(() => {
        let timer = setTimeout(() => {
            changeSnackBar('');
        }, 3 * 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [snackBar]);

    return (
        <>
            {snackBar && (
                <div
                    className={classnames({
                        Snackbar: true,
                        SnackbarError: isError(snackBar),
                        SnackbarSuccess: isSuccess(snackBar),
                    })}
                >
                    {snackBar}
                </div>
            )}
        </>
    );
}

export default Snackbar;

import { FunctionComponent } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useUserMe } from '../../hooks/userme';

interface IProtectedRoute {
    component: any;
    path: string;
}

const ProtectedRoute: FunctionComponent<IProtectedRoute> = ({
    component: Component,
    ...restProps
}) => {
    const [userMe] = useUserMe();
    const accessToken = userMe ? userMe.token : '';
    const isLoggedIn = !!accessToken;

    return (
        <Route
            {...restProps}
            render={(props) => {
                return isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/iniciar-sesion',
                            state: {
                                from: props.location,
                            },
                        }}
                    />
                );
            }}
        />
    );
};

export default ProtectedRoute;

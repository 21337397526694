import { Component, Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../commons/components/header/Header';
import Breadcrumb from '../../commons/components/breadcrumb/Breadcrumb';
import Footer from '../../commons/components/footer/Footer';
import useSubmodules from '../../commons/hooks/modules';
import Spinner from '../../commons/components/spinner/Spinner';
import './submodule.scss';

interface IUseParams {
    id: number | string;
}

function Submodule() {
    const { id } = useParams() as IUseParams;
    const { idSubmodule, view, breadcrumb } = useSubmodules({
        idSubmodule: id,
    });
    const ViewComponent = lazy(
        () => import('./view' + idSubmodule + '/View' + idSubmodule)
    );

    return (
        <div className="ContentFull">
            <Header></Header>
            <div className="Content">
                <Breadcrumb text={breadcrumb}></Breadcrumb>
                <div className="ContentInner">
                    {idSubmodule && view && (
                        <Suspense fallback={<Spinner />}>
                            <ViewComponent />
                        </Suspense>
                    )}
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Submodule;

import { useEffect, useState } from 'react';
import './table.scss';

interface ITable {
    totalPages: number | undefined | null;
    msgError: string;
    activePage: number | undefined | null;
    handlePaged: any;
}

function TablePaged(props: ITable) {
    const [totalPages, setTotalPages] = useState<number | null>(null);
    const [activePage, setActivePage] = useState<number>(0);
    const [msgError, setMsgError] = useState<string>('');

    const getPaged = (): number[] => {
        const num = totalPages || 1;
        let paged = [];

        for (let ind = 0; ind < num; ind++) {
            paged.push(ind + 1);
        }

        return paged;
    };

    useEffect(() => {
        setTotalPages(props.totalPages!);
        setMsgError(props.msgError!);
        setActivePage(props.activePage!);
    }, [props]);

    return (
        <>
            <div className="TableNav">
                {!msgError && (
                    <ul>
                        {activePage > 1 && (
                            <li
                                className="Arrow"
                                onClick={() =>
                                    props.handlePaged(activePage - 1)
                                }
                            >
                                Anterior
                            </li>
                        )}
                        {getPaged().map((item, ind) => (
                            <li
                                key={'paged' + ind}
                                className={item === activePage ? 'Active' : ''}
                                onClick={() => props.handlePaged(item)}
                            >
                                {item}
                            </li>
                        ))}
                        {activePage < getPaged().length && (
                            <li
                                className="Arrow"
                                onClick={() =>
                                    props.handlePaged(activePage + 1)
                                }
                            >
                                Siguiente
                            </li>
                        )}
                    </ul>
                )}
            </div>
        </>
    );
}

export default TablePaged;

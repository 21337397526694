import { Fragment, useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Transition, Disclosure } from '@headlessui/react';
import classnames from 'classnames';
import { useUserMe } from '../../../commons/hooks/userme';
import Popover from '../popover/popover';
import logo from '../../../images/logo.svg';
import IconArrowDown from '../../../images/arrow-down.svg';
import IconHamburger from '../../../images/hamburger.svg';
import IconClose from '../../../images/close-gray.svg';
import { IModulos } from '../../interfaces/user';
import './header.scss';

interface IHeader {
    name?: string;
}

function Header(props: IHeader) {
    const [userMe] = useUserMe();
    const [menu, setMenu] = useState<IModulos[]>([]);
    const [name, setName] = useState<string>('');
    const myInput = useRef<any>(null);

    useEffect(() => {
        if (userMe?.modulos) {
            setMenu(userMe.modulos);
        }

        if (userMe?.info?.user?.nombre) {
            setName(userMe.info.user.nombre);
        }
    }, [userMe]);

    const closeMenu = () => {
        myInput.current?.click();
    }

    return (
        <div className="Header">
            <header className="HeaderInner">
                <nav className="HeaderNav">
                    <h1 className="HeaderLogo">
                        <NavLink to={'/'}>
                            <img
                                src={logo}
                                alt="Empresas que transforman el Perú"
                            />
                        </NavLink>
                    </h1>
                    <input ref={myInput} type="hidden" />
                    <div className="separate"></div>
                    <div className="HeaderItems">
                        {menu.map((el, ind) => (
                            <span
                                className="HeaderSubItem"
                                key={'HeaderSubItem-' + ind}
                            >
                                <Menu as={Fragment}>
                                    {({ open }) => (
                                        <>
                                            <Menu.Button as={Fragment}>
                                                <span
                                                    className={classnames({
                                                        HeaderItem: true,
                                                        HeaderItemOpen: open,
                                                    })}
                                                    key={
                                                        'HeaderSubItemSpan-' +
                                                        ind
                                                    }
                                                >
                                                    {el.name}
                                                    <img
                                                        className="HeaderIcon"
                                                        src={IconArrowDown}
                                                        alt=""
                                                    />
                                                </span>
                                            </Menu.Button>

                                            <Transition
                                                enter="PopoverEnter"
                                                enterFrom="PopoverEnterFrom"
                                                enterTo="PopoverEnterTo"
                                                leave="PopoverLeave"
                                                leaveFrom="PopoverLeaveFrom"
                                                leaveTo="PopoverLeaveTo"
                                                as={Fragment}
                                            >
                                                <Menu.Items as={Fragment}>
                                                    <ul
                                                        className={classnames({
                                                            HeaderUl: true,
                                                            HeaderUlOpen: open,
                                                        })}
                                                    >
                                                        {el.submodulo.map(
                                                            (subEl, indS) => (
                                                                <Menu.Item
                                                                    as="li"
                                                                    className="HeaderLi"
                                                                    key={
                                                                        'subitem-' +
                                                                        indS
                                                                    }
                                                                >
                                                                    {({
                                                                        active,
                                                                    }) => (
                                                                        <NavLink onClick={closeMenu}
                                                                            className={`${
                                                                                active &&
                                                                                'active'
                                                                            }`}
                                                                            to={
                                                                                '/submodulo/' +
                                                                                subEl.idsubmodulo
                                                                            }
                                                                        >
                                                                            {
                                                                                subEl.submodulo
                                                                            }
                                                                        </NavLink>
                                                                    )}
                                                                </Menu.Item>
                                                            )
                                                        )}
                                                    </ul>
                                                </Menu.Items>
                                            </Transition>
                                        </>
                                    )}
                                </Menu>
                            </span>
                        ))}
                        <span className="HeaderSubItemMobile">
                            <Menu as={Fragment}>
                                {({ open }) => (
                                    <>
                                        <Menu.Button as={Fragment}>
                                            <span
                                                className={classnames({
                                                    HeaderItemMobile: true,
                                                    HeaderItemMobileOpen: open,
                                                })}
                                            >
                                                <img
                                                    className="HeaderIconHamb"
                                                    src={
                                                        open
                                                            ? IconClose
                                                            : IconHamburger
                                                    }
                                                    alt=""
                                                />
                                            </span>
                                        </Menu.Button>

                                        <Transition
                                            enter="PopoverEnter"
                                            enterFrom="PopoverEnterFrom"
                                            enterTo="PopoverEnterTo"
                                            leave="PopoverLeave"
                                            leaveFrom="PopoverLeaveFrom"
                                            leaveTo="PopoverLeaveTo"
                                            as={Fragment}
                                        >
                                            <Menu.Items as={Fragment}>
                                                <ul
                                                    className={classnames({
                                                        HeaderUlMobile: true,
                                                        HeaderUlMobileOpen:
                                                            open,
                                                    })}
                                                >
                                                    {menu.map((menuM, indM) => (
                                                        <Menu.Item
                                                            as="li"
                                                            className="HeaderLiMobile"
                                                            key={
                                                                'subitemM-' +
                                                                indM
                                                            }
                                                            disabled
                                                        >
                                                            {({ active }) => (
                                                                <>
                                                                    <Disclosure
                                                                        as={
                                                                            Fragment
                                                                        }
                                                                        defaultOpen={
                                                                            true
                                                                        }
                                                                        key={
                                                                            'block-' +
                                                                            indM
                                                                        }
                                                                    >
                                                                        {({
                                                                            open,
                                                                        }) => (
                                                                            <>
                                                                                <Disclosure.Button
                                                                                    as="div"
                                                                                    className="HeaderLiMobileCnt"
                                                                                >
                                                                                    <span
                                                                                        className={`${
                                                                                            active &&
                                                                                            'active'
                                                                                        }`}
                                                                                    >
                                                                                        {
                                                                                            menuM.name
                                                                                        }
                                                                                    </span>
                                                                                    <img
                                                                                        className={classnames(
                                                                                            {
                                                                                                btnArrow:
                                                                                                    true,
                                                                                                open: open,
                                                                                            }
                                                                                        )}
                                                                                        src={
                                                                                            IconArrowDown
                                                                                        }
                                                                                        alt=""
                                                                                    />
                                                                                </Disclosure.Button>
                                                                                {open && (
                                                                                    <>
                                                                                        <Disclosure.Panel
                                                                                            as={
                                                                                                Fragment
                                                                                            }
                                                                                        >
                                                                                            <ul className="HeaderLiMobileUl">
                                                                                                {menuM.submodulo.map(
                                                                                                    (
                                                                                                        menuSM,
                                                                                                        indSM
                                                                                                    ) => (
                                                                                                        <li
                                                                                                            className="HeaderLiMobileUlLi"
                                                                                                            key={
                                                                                                                'menuSM' +
                                                                                                                indSM
                                                                                                            }
                                                                                                        >
                                                                                                            <NavLink onClick={closeMenu}
                                                                                                                className={`${
                                                                                                                    active &&
                                                                                                                    'active'
                                                                                                                }`}
                                                                                                                to={
                                                                                                                    '/submodulo/' +
                                                                                                                    menuSM.idsubmodulo
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    menuSM.submodulo
                                                                                                                }
                                                                                                            </NavLink>
                                                                                                        </li>
                                                                                                    )
                                                                                                )}
                                                                                            </ul>
                                                                                        </Disclosure.Panel>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Disclosure>
                                                                </>
                                                            )}
                                                        </Menu.Item>
                                                    ))}
                                                </ul>
                                            </Menu.Items>
                                        </Transition>
                                    </>
                                )}
                            </Menu>
                        </span>
                        <span className="separates"></span>
                        <Popover name={name}></Popover>
                    </div>
                </nav>
            </header>
        </div>
    );
}

export default Header;

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Axios from 'axios';
import { configure } from 'axios-hooks';
import Dashboard from './bussines/dashboard/Dashboard';
import Submodule from './bussines/submodule/Submodule';
import Details from './bussines/details/Details';
import Login from './bussines/authentication/login/Login';
import ProtectedRoute from './commons/components/protected-route/Protected-route';
import UnProtectedRoute from './commons/components/protected-route/UnProtected-route';
import Loader from './commons/components/loader/Loader';
import Snackbar from './commons/components/snackbar/Snackbar';
import {
    InterceptorConfig,
    InterceptorError,
    InterceptorResponseConfig,
    InterceptorResponseError,
} from './commons/interceptor/interceptor';
import { AppContextProvider } from './commons/context/App-context';
import './App.scss';
import enviroment from './enviroments/enviroment';
import { checkSepUpTime } from '../src/commons/utils/utils';
import 'react-datepicker/dist/react-datepicker.css';

checkSepUpTime();
const axios = Axios.create({
    // withCredentials: false,
    baseURL: enviroment.apiURL,
});
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.interceptors.request.use(InterceptorConfig, InterceptorError);
axios.interceptors.response.use(
    function (response) {
        //console.log(response, '<<< response');
        // Do something with response data
        return response;
    },
    function (error) {
        // console.log(error, '<<< error');
        // Do something with response error
        return Promise.reject(error);
    }
);

configure({ axios });

function App() {
    return (
        <div className="App">
            <AppContextProvider>
                <Router>
                    <Switch>
                        <UnProtectedRoute
                            path="/iniciar-sesion"
                            component={Login}
                        ></UnProtectedRoute>
                        <ProtectedRoute
                            path="/submodulo/:id"
                            component={Submodule}
                        ></ProtectedRoute>
                        <ProtectedRoute
                            path="/detalle/submodulo/:id/:idcheckevento"
                            component={Details}
                        ></ProtectedRoute>
                        <ProtectedRoute
                            path="/"
                            component={Dashboard}
                        ></ProtectedRoute>
                    </Switch>
                </Router>
                <Loader />
                <Snackbar />
            </AppContextProvider>
        </div>
    );
}

export default App;

import { useContext, useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactDataGrid from 'react-data-grid';
import classnames from 'classnames';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import HttpAxios from '../../../commons/hooks/http';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import { EErrorMsg } from '../../../commons/statics/controls';
import { useUserMe } from '../../../commons/hooks/userme';
import { AppContext } from '../../../commons/context/App-context';
import { ESelectCustom, ESelectTheme } from '../../../commons/statics/controls';
import IconPdf from '../../../images/pdf.svg';
import IconExcel from '../../../images/excel.svg';
import IconCheckOK from '../../../images/check-ok.svg';
import IconCheckNone from '../../../images/check-none.svg';
import { ITiendas } from '../../../commons/interfaces/user';
import enviroment from '../../../enviroments/enviroment';
import { Errors } from '../../../commons/constants/submodules';
import { IParameters } from '../../../commons/interfaces/items';
import es from 'date-fns/locale/es';
import './view54.scss';

registerLocale('es', es);

const defaultOption = {
    value: 0,
    label: 'TODOS',
};
let params: IParameters = {};
let memoParams: IParameters = {};

function View54() {
    const {
        isLoading,
        changeIsLoading,
        changeSnackBar,
        changeUrlBackTop,
        changePayload54,
        payload54,
    } = useContext(AppContext);
    const selectStyles = ESelectCustom;
    const selectTheme = ESelectTheme;
    const history = useHistory();
    const [noData, setNoData] = useState<boolean>(false);
    const compPDF = (data: any) => {
        return data?.row?.urlinforme ? (
            <a href={data.row.urlinforme} target="blank">
                <img className="View54Pdf" src={IconPdf} alt="PDF" />
            </a>
        ) : (
            <></>
        );
    };
    const compCheck = (data: any) => {
        return data?.row?.flagcerrado ? (
            <img className="View54CheckOk" src={IconCheckOK} alt="Check" />
        ) : (
            <img
                className="View54CheckNone"
                src={IconCheckNone}
                alt="No Check"
            />
        );
    };
    const headerColumns = [
        {
            key: 'fecha',
            name: 'FECHA',
            frozen: true,
            resizable: false,
            width: '8%',
            minWidth: 100,
        },
        { key: 'nombretienda', name: 'TIENDA', width: '14%', minWidth: 100 },
        { key: 'abreviado', name: 'TIPO', width: '14%', minWidth: 100 },
        { key: 'incidencias', name: 'INCIDENCIAS', width: '4%', minWidth: 50 },
        { key: 'responsable', name: 'RESPONSABLE', width: '20%', minWidth: 250 },
        { key: 'horainicio', name: 'HORA INICIO', width: '8%', minWidth: 50 },
        { key: 'horafin', name: 'HORA FIN', width: '8%', minWidth: 50 },
        { key: 'estadocumple', name: 'CUMPLIMIENTO', width: '8%', minWidth: 50 },
        { key: 'flagcerrado', name: 'CERRADO', width: '8%', minWidth: 50, formatter: compCheck, cellClass: () => { return 'colcenter'; } },
        { key: 'urlinforme', name: 'VER INFORME', width: '8%', minWidth: 50, formatter: compPDF, cellClass: () => { return 'colcenter'; } },
    ];
    const [msgError, setMsgError] = useState<string>('');
    const [useMe, setUseMe] = useUserMe();
    const [stores, setStores] = useState<Array<ITiendas> | null>(null);
    const [checklists, setLhecklists] = useState([]);
    const [lists, setLists] = useState(defaultOption);
    const [store, setStore] = useState(defaultOption);
    const [rows, setRows] = useState<Array<any>>([]);
    const [columns, setColumns] = useState(headerColumns);
    const [parameters, setParameters] = useState<IParameters>({});
    const [totalPerPage, setTotalPerPage] = useState(0);
    const [nextPage, setNextPage] = useState(2);
    const [totalPages, setTotalPages] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [initLoading, setInitLoading] = useState<boolean>(false);
    const [endDate, setEndDate] = useState(new Date());
    const subsDays = dayjs(new Date()).subtract(7, 'day');
    const [startDate, setStartDate] = useState(subsDays.toDate());

    const btnSearchRef = useRef<any>(null);

    const getItems = (items: any, hasDefault?: boolean): any => {
        let temp: any = hasDefault ? [] : [defaultOption];

        items?.forEach((s: any) => {
            temp.push({
                value: s.id,
                label: s.descripcion,
            });
        });

        return temp;
    };

    const getTypes = () => {
        HttpAxios.post('/checklists', {
            idmodulo: -1,
            todos: true,
            identidad: 0,
        })
            .then((res: any) => {
                const items: Array<any> = res?.data?.response || [];
                const options = getItems(items, true);
                setLhecklists(options);
                if (payload54) {
                    const elem = options.filter(
                        (el: any) => el.value === payload54?.idcheck
                    );
                    if (elem) {
                        onChangeChecklists(elem[0]);
                    }
                    searchByBack();
                    changePayload54(null);
                } else {
                    changePayload54(null);
                }
            })
            .catch((err) => {
                changePayload54(null);
            });
    };

    const search = (
        page: number = payload54?.page || 1,
        isPaginated?: boolean
    ) => {
        if (!isPaginated) {
            const rangeStart = startDate
                ? dayjs(startDate).format(enviroment.search.formatDate)
                : '';
            const rangeEnd = endDate
                ? dayjs('' + endDate).format(enviroment.search.formatDate)
                : '';

            params = {
                idtienda: store?.value || 0,
                idcheck: lists?.value || 0,
                fecha_ini: rangeStart,
                fecha_fin: rangeEnd || rangeStart,
                idmodulo: 11,
                flagcerrado: false,
                limit: enviroment.search.limitPerPage,
            };
            memoParams = params;
        }

        let paramsSearch = params;
        paramsSearch.page = page;
        memoParams.page = page;
        changeIsLoading(true);
        setInitLoading(true);
        setMsgError('');
        setRows([]);

        HttpAxios.post('/checklists/dashboard_websearch', paramsSearch)
            .then((res: any) => {
                const data = res?.data || {};
                const response = data.response || [];
                if (response.length) {
                    const {
                        nextpage = 2,
                        page = 1,
                        total_pages = 0,
                        total_rows = 0,
                    } = data;
                    setNoData(false);
                    setRows(response);
                    setTotalPerPage(total_rows || response.length);
                    setActivePage(page);
                    setTotalPages(total_pages);
                    setNextPage(nextpage);
                    setParameters(memoParams);
                    setMsgError('');
                } else {
                    setNoData(true);
                    setRows([]);
                }

                changeIsLoading(false);
                setInitLoading(false);
            })
            .catch(() => {
                setMsgError(Errors.generic);
                changeIsLoading(false);
                setInitLoading(false);
            });
    };

    const handlePaged = (ind: number) => {
        search(ind, true);
    };

    const getPaged = (): number[] => {
        const num = totalPages || 1;
        let paged = [];

        for (let ind = 0; ind < num; ind++) {
            paged.push(ind + 1);
        }

        return paged;
    };

    const handleExport = () => {
        changeIsLoading(true);

        HttpAxios.post('/checklists/dashboard_websearch_xls', parameters)
            .then((res: any) => {
                const { err, url, response } = res?.data || {};
                changeIsLoading(false);

                if (err) {
                    changeSnackBar(EErrorMsg.generic);
                } else {
                    handleDownload(url || response);
                }
            })
            .catch(() => {
                changeIsLoading(false);
                changeSnackBar(EErrorMsg.generic);
            });
    };

    const handleDownload = (urlFile: string = '') => {
        const link = document.createElement('a');
        link.href = urlFile;
        link.setAttribute(
            'download',
            `${urlFile.split('/')[urlFile.split('/').length - 1]}`
        );
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            link?.parentNode?.removeChild(link);
        }, 100);
    };

    const onChangeChecklists = (evt: any) => {
        setLists({ ...evt });
    };

    const onChangeStores = (evt: any) => {
        setStore({ ...evt });
    };

    const onChangeRange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const rowClick = (obj: any, r: any): void => {
        const allows = [0, 1, 2, 3, 4, 5];
        changePayload54(memoParams);
        if (allows.includes(r.idx)) {
            const url = '/detalle/submodulo/53/' + obj?.idcheckevento;
            history.push(url);
        } else if (r.idx === 6) {
            obj.urlinforme && window.open(obj.urlinforme, '_blank');
        }
    };

    const initBackValues = (stores: Array<ITiendas> | null) => {
        if (payload54) {
            const { fecha_ini, fecha_fin } = payload54;
            const y: number = Number(fecha_ini.substring(0, 4));
            const m: number = Number(fecha_ini.substring(4, 6)) - 1;
            const d: number = Number(fecha_ini.substring(6, 8));
            const yEnd: number = Number(fecha_fin.substring(0, 4));
            const mEnd: number = Number(fecha_fin.substring(4, 6)) - 1;
            const dEnd: number = Number(fecha_fin.substring(6, 8));

            setStartDate(new Date(y, m, d));
            setEndDate(new Date(yEnd, mEnd, dEnd));

            const elem: any = (stores || []).filter(
                (el: any) => el.id === payload54?.idtienda
            );
            if (elem) {
                const el = getItems(elem);
                onChangeStores(el.length === 1 ? el[0] : el[1]);
            }
        }
    };

    const searchByBack = () => {
        if (payload54 && lists && btnSearchRef) {
            btnSearchRef.current.click();
        }
    };

    useEffect(() => {
        const stores: Array<ITiendas> | null = useMe?.tiendas || null;
        setStores(stores);
        initBackValues(stores);
        getTypes();
        !payload54 && search();
        changeUrlBackTop('/submodulo/54');
        changeIsLoading(true);
    }, []);

    return (
        <>
            <div className="View54Search">
                <div className="row-4">
                    <div className="col">
                        <label className="label">Tienda</label>
                        <Select
                            id="store"
                            name="store"
                            styles={selectStyles}
                            theme={selectTheme}
                            value={store}
                            onChange={(evt) => onChangeStores(evt)}
                            placeholder="Selecciona una tienda"
                            options={getItems(stores)}
                        />
                    </div>
                    <div className="col">
                        <label className="label">Tipo CheckList</label>
                        <Select
                            id="lists"
                            name="lists"
                            styles={selectStyles}
                            theme={selectTheme}
                            value={lists}
                            onChange={(evt) => onChangeChecklists(evt)}
                            placeholder="Selecciona un tipo"
                            options={checklists}
                        />
                    </div>
                    <div className="col">
                        <label className="label">Rango de fechas</label>
                        <DatePicker
                            onChange={onChangeRange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange={true}
                            locale="es"
                            className="input"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Selecione un rango de fecha"
                            maxDate={new Date()}
                        />
                    </div>
                    <div className="col">
                        <label className="label empty">&nbsp;</label>
                        <button
                            type="button"
                            onClick={() => search()}
                            className="button buttonFull"
                            ref={btnSearchRef}
                        >
                            Buscar
                        </button>
                    </div>
                </div>
            </div>

            <div className="View54Body">
                {noData || msgError ? (
                    !initLoading && (
                        <div
                            className={classnames({
                                noData: noData,
                                msgError: msgError,
                            })}
                        >
                            {msgError
                                ? msgError
                                : 'No se encontró resultado en la búsqueda'}
                        </div>
                    )
                ) : (
                    <>
                        <div className="View54SearchActions">
                            <div className="View54SearchActionsTotal">
                                {totalPerPage > 0 &&
                                    'Total de registros: ' + totalPerPage}
                            </div>
                            <div className="separate"></div>
                            {totalPerPage > 0 && (
                                <button
                                    className="button buttonExcel"
                                    type="button"
                                    onClick={handleExport}
                                >
                                    <img
                                        src={IconExcel}
                                        alt="Excel"
                                        className="buttonIconLeft"
                                    />
                                    Exportar
                                </button>
                            )}
                        </div>
                        <ReactDataGrid
                            className="View54Table"
                            columns={columns}
                            rows={rows}
                            onRowsChange={setRows}
                            onRowClick={rowClick}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: true,
                            }}
                        />
                        <div className="View54SearchNav">
                            {!msgError && (
                                <ul>
                                    {activePage > 1 && (
                                        <li
                                            className="Arrow"
                                            onClick={() =>
                                                handlePaged(activePage - 1)
                                            }
                                        >
                                            Anterior
                                        </li>
                                    )}
                                    {getPaged().map((item, ind) => (
                                        <li
                                            key={'paged' + ind}
                                            className={
                                                item === activePage
                                                    ? 'Active'
                                                    : ''
                                            }
                                            onClick={() => handlePaged(item)}
                                        >
                                            {item}
                                        </li>
                                    ))}
                                    {activePage < getPaged().length && (
                                        <li
                                            className="Arrow"
                                            onClick={() =>
                                                handlePaged(activePage + 1)
                                            }
                                        >
                                            Siguiente
                                        </li>
                                    )}
                                </ul>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default View54;

import enviroment from '../../../enviroments/enviroment';
import './footer.scss';

function Footer() {
    return (
        <div className="Footer">
            CasaIdeas - Todos los derechos reservados (R). Versión{' '}
            {enviroment.version}
        </div>
    );
}

export default Footer;

import { useContext, useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactDataGrid from 'react-data-grid';
import classnames from 'classnames';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import HttpAxios from '../../../commons/hooks/http';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import TableActions from '../../../commons/components/table/TableActions';
import TablePaged from '../../../commons/components/table/TablePaged';
import ModalEdit55 from '../../../commons/components/modal/ModalEdit55';
import { useUserMe } from '../../../commons/hooks/userme';
import { AppContext } from '../../../commons/context/App-context';
import { ESelectCustom, ESelectTheme } from '../../../commons/statics/controls';
import { ITiendas } from '../../../commons/interfaces/user';
import enviroment from '../../../enviroments/enviroment';
import { Errors } from '../../../commons/constants/submodules';
import { IParameters } from '../../../commons/interfaces/items';
import IconEdit from '../../../images/edit.svg';
import es from 'date-fns/locale/es';
import './view55.scss';

registerLocale('es', es);

const defaultOption = {
    value: 0,
    label: 'TODOS',
};
let params: IParameters = {};
let memoParams: IParameters = {};

function View55() {
    const {
        changeIsLoading,
        changeUrlBackTop,
        changePayload55,
        changeShowModalEdit55,
        payload55,
    } = useContext(AppContext);
    const history = useHistory();
    const [noData, setNoData] = useState<boolean>(false);
    const compStatus = (data: any, item: string) => {
        return (
            <>
                {data?.row[item] ? (
                    <div className="View55Cell">
                        {data.row[item]} <img src={IconEdit} />
                    </div>
                ) : (
                    ''
                )}
            </>
        );
    };
    const headerColumns = [
        {
            key: 'tienda',
            name: 'TIENDA',
            frozen: true,
            resizable: true,
            width: 170,
        },
        {
            key: 'nrocorrelativo',
            name: '#CORRELATIVO',
            frozen: true,
            resizable: true,
            width: 150,
        },
        { key: 'hum', name: 'HUM', width: 150 },
        { key: 'sku', name: 'SKU', width: 150 },
        { key: 'producto', name: 'PRODUCTO', width: 400 },
        { key: 'cantidad', name: 'CANTIDAD', width: 120 },
        { key: 'tipoincidencia', name: 'TIPO INCIDENCIA', width: 150 },
        { key: 'nroentrega', name: '#ENTREGA', width: 130 },
        { key: 'nroguia', name: '#GUÍA', width: 150 },
        { key: 'fecha', name: 'FECHA', width: 120 },
        { key: 'nrosap', name: 'DOCUMENTO SAP', width: 150 },
        {
            key: 'estadoatencion',
            name: 'ESTADO ATENCIÓN',
            width: 150,
            formatter: (data: any) => compStatus(data, 'estadoatencion'),
        },
        { key: 'diasatraso', name: 'DÍAS ATRASO', width: 140 },
    ];
    const [msgError, setMsgError] = useState<string>('');
    const [useMe, setUseMe] = useUserMe();
    const [stores, setStores] = useState<Array<ITiendas> | null>(null);
    const [checklists, setLhecklists] = useState([]);
    const [status, setStatus] = useState([]);
    const [lists, setLists] = useState(defaultOption);
    const [stateAttention, setStateAttention] = useState(defaultOption);
    const [store, setStore] = useState(defaultOption);
    const [rows, setRows] = useState<Array<any>>([]);
    const [columns, setColumns] = useState(headerColumns);
    const [parameters, setParameters] = useState<IParameters>({});
    const [totalPerPage, setTotalPerPage] = useState(0);
    const [nextPage, setNextPage] = useState(2);
    const [totalPages, setTotalPages] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [initLoading, setInitLoading] = useState<boolean>(false);
    const selectStyles = ESelectCustom;
    const selectTheme = ESelectTheme;
    const [endDate, setEndDate] = useState(new Date());
    const subsDays = dayjs(new Date()).subtract(7, 'day');
    const [startDate, setStartDate] = useState(subsDays.toDate());
    const [fullName, setFullName] = useState('');
    const btnSearchRef = useRef<any>(null);
    const [rowSelected, setRowSelected] = useState(null);
    const [idxSelected, setIdxSelected] = useState(null);
    const [statusSelected, setStatusSelected] = useState([]);

    const getItems = (items: any, hasDefault?: boolean): any => {
        let temp: any = hasDefault ? [] : [defaultOption];

        items?.forEach((s: any) => {
            temp.push({
                value: s.id,
                label: s.descripcion,
            });
        });

        return temp;
    };

    const getTypes = () => {
        HttpAxios.post('/entidad/estados', {
            id: 9,
            idestadopadre: 0,
            todos: true,
        })
            .then((res: any) => {
                const items: Array<any> = res?.data?.response || [];
                const options = getItems(items, true);
                setLhecklists(options);
                if (payload55) {
                    const elem = options.filter(
                        (el: any) => el.value === payload55?.idtipoincidencia
                    );
                    if (elem) {
                        onChangeChecklists(elem[0]);
                    }
                    searchByBack();
                }
                changePayload55(null);
            })
            .catch((err) => {
                changePayload55(null);
            });
    };

    const getStatus = () => {
        HttpAxios.post('/entidad/estados', {
            id: 10,
            idestadopadre: 0,
            todos: true,
        })
            .then((res: any) => {
                const items: Array<any> = res?.data?.response || [];
                const options = getItems(items, true);
                setStatus(options);
                if (payload55) {
                    const elem = options.filter(
                        (el: any) => el.value === payload55?.idestado
                    );
                    if (elem) {
                        onChangeStateAtention(elem[0]);
                    }
                }
                getTypes();
            })
            .catch((err) => {
                getTypes();
            });
    };

    const search = (
        page: number = payload55?.page || 1,
        isPaginated?: boolean
    ) => {
        if (!isPaginated) {
            const rangeStart = startDate
                ? dayjs(startDate).format(enviroment.search.formatDate)
                : '';
            const rangeEnd = endDate
                ? dayjs('' + endDate).format(enviroment.search.formatDate)
                : '';

            params = {
                idtienda: store?.value || 0,
                idtipoincidencia: lists?.value || 0,
                idestado: stateAttention?.value || 0,
                encargado: fullName || '',
                todos: false,
                fecha_ini: rangeStart,
                fecha_fin: rangeEnd || rangeStart,
                limit: enviroment.search.limitPerPage,
            };
            memoParams = params;
        }

        let paramsSearch = params;
        paramsSearch.page = page;
        memoParams.page = page;
        changeIsLoading(true);
        setInitLoading(true);
        setMsgError('');
        setRows([]);

        HttpAxios.post('/incidencias/web_search', paramsSearch)
            .then((res: any) => {
                const data = res?.data || {};
                const response = data.response || [];
                if (response.length) {
                    const {
                        nextpage = 2,
                        page = 1,
                        total_pages = 0,
                        total_rows = 0,
                    } = data;
                    setNoData(false);
                    setRows(response);
                    setTotalPerPage(total_rows || response.length);
                    setActivePage(page);
                    setTotalPages(total_pages);
                    setNextPage(nextpage);
                    setParameters(memoParams);
                    setMsgError('');
                } else {
                    setNoData(true);
                    setRows([]);
                }

                changeIsLoading(false);
                setInitLoading(false);
            })
            .catch(() => {
                setMsgError(Errors.generic);
                changeIsLoading(false);
                setInitLoading(false);
            });
    };

    const handlePaged = (ind: number) => {
        search(ind, true);
    };

    const onChangeChecklists = (evt: any) => {
        setLists(evt);
    };

    const onChangeFullName = (evt: any) => {
        setFullName(evt.target.value);
    };

    const onChangeStateAtention = (evt: any) => {
        setStateAttention(evt);
    };

    const onChangeStores = (evt: any) => {
        setStore(evt);
    };

    const onChangeRange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const openModalEdit = (data: any, r: any) => {
        setRowSelected(JSON.parse(JSON.stringify(data)));
        setStatusSelected(JSON.parse(JSON.stringify(status)));
        setIdxSelected(JSON.parse(JSON.stringify(r)));
        changeShowModalEdit55(true);
    };

    const onSuccess = (data: any) => {
        const rowsFlat = JSON.parse(JSON.stringify(rows));
        const newRows = rowsFlat.map((r: any) => {
            let newR = r;
            if (r.idincidenciadetalle === data.row.idincidenciadetalle) {
                newR.estadoatencion = data.stateAttention.label;
            }
            return newR;
        });

        setRows(newRows);
    };

    const rowClick = (obj: any, r: any): void => {
        if (r.idx === 14) {
            openModalEdit(obj, r);
        } else {
            changePayload55(memoParams);
            const url = '/detalle/submodulo/55/' + obj?.idincidenciadetalle;
            history.push(url);
        }
    };

    const initBackValues = (stores: Array<ITiendas> | null) => {
        if (payload55) {
            const { fecha_ini, fecha_fin } = payload55;
            const y: number = Number(fecha_ini.substring(0, 4));
            const m: number = Number(fecha_ini.substring(4, 6)) - 1;
            const d: number = Number(fecha_ini.substring(6, 8));
            const yEnd: number = Number(fecha_fin.substring(0, 4));
            const mEnd: number = Number(fecha_fin.substring(4, 6)) - 1;
            const dEnd: number = Number(fecha_fin.substring(6, 8));

            setStartDate(new Date(y, m, d));
            setEndDate(new Date(yEnd, mEnd, dEnd));

            const elem: any = (stores || []).filter(
                (el: any) => el.id === payload55?.idtienda
            );
            if (elem) {
                const el = getItems(elem);
                onChangeStores(el.length === 1 ? el[0] : el[1]);
            }

            setFullName(payload55?.encargado! || '');
        }
    };

    const searchByBack = () => {
        if (payload55 && lists && btnSearchRef) {
            btnSearchRef.current.click();
        }
    };

    useEffect(() => {
        const stores: Array<ITiendas> | null = useMe?.tiendas || null;
        setStores(stores);
        initBackValues(stores);
        getStatus();
        !payload55 && search();
        changeUrlBackTop('/submodulo/55');
        changeIsLoading(true);
    }, []);

    return (
        <>
            <div className="View55Search">
                <div className="row-6">
                    <div className="col">
                        <label className="label">Tienda</label>
                        <Select
                            id="store"
                            name="store"
                            styles={selectStyles}
                            theme={selectTheme}
                            value={store}
                            onChange={(evt) => onChangeStores(evt)}
                            placeholder="Selecciona una tienda"
                            options={getItems(stores)}
                        />
                    </div>
                    <div className="col">
                        <label className="label">Tipo Incidencia</label>
                        <Select
                            id="lists"
                            name="lists"
                            styles={selectStyles}
                            theme={selectTheme}
                            value={lists}
                            onChange={(evt) => onChangeChecklists(evt)}
                            placeholder="Selecciona un tipo"
                            options={checklists}
                        />
                    </div>
                    <div className="col">
                        <label className="label">Estado Atención</label>
                        <Select
                            id="stateAttention"
                            name="stateAttention"
                            styles={selectStyles}
                            theme={selectTheme}
                            value={stateAttention}
                            onChange={(evt) => onChangeStateAtention(evt)}
                            placeholder="Selecciona un estado"
                            options={status}
                        />
                    </div>
                    <div className="col">
                        <label className="label">Rango de fechas</label>
                        <DatePicker
                            selected={startDate}
                            onChange={onChangeRange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange={true}
                            locale="es"
                            className="input"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Selecione un rango de fecha"
                            maxDate={new Date()}
                        />
                    </div>
                    <div className="col">
                        <label className="label">Encargado Recepción</label>
                        <input
                            className="input"
                            id="fullName"
                            name="fullName"
                            placeholder="Encargado de recepción"
                            value={fullName}
                            onChange={(evt) => onChangeFullName(evt)}
                        />
                    </div>
                    <div className="col">
                        <label className="label empty">&nbsp;</label>
                        <button
                            type="button"
                            onClick={() => search()}
                            className="button buttonFull"
                            ref={btnSearchRef}
                        >
                            Buscar
                        </button>
                    </div>
                </div>
            </div>

            <div className="View55Body">
                {noData || msgError ? (
                    !initLoading && (
                        <div
                            className={classnames({
                                noData: noData,
                                msgError: msgError,
                            })}
                        >
                            {msgError
                                ? msgError
                                : 'No se encontró resultado en la búsqueda'}
                        </div>
                    )
                ) : (
                    <>
                        <TableActions
                            totalPerPage={totalPerPage}
                            paramsExport={parameters}
                            urlExport={'/incidencias/web_search_xls'}
                        />
                        <ReactDataGrid
                            className="View55Table"
                            columns={columns}
                            rows={rows}
                            onRowsChange={setRows}
                            onRowClick={rowClick}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: true,
                            }}
                        />
                        <TablePaged
                            msgError={msgError}
                            activePage={activePage}
                            handlePaged={handlePaged}
                            totalPages={totalPages}
                        />
                    </>
                )}
            </div>
            <ModalEdit55
                row={rowSelected}
                status={statusSelected}
                onSuccess={onSuccess}
                idxSelected={idxSelected}
            ></ModalEdit55>
        </>
    );
}

export default View55;

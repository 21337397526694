import { useState, useEffect } from 'react';

interface IGeo {
    altitude: number | string;
    latitude: number | string;
    longitude: number | string;
}

const useGeo = (): IGeo => {
    const [altitude, setAltitude] = useState<number | string>('');
    const [latitude, setLatitude] = useState<number | string>('');
    const [longitude, setLongitude] = useState<number | string>('');

    const getPosition = (position: any) => {
        setLatitude((position?.coords?.latitude as number) || '');
        setAltitude((position?.coords?.altitude as number) || '');
        setLongitude((position?.coords?.longitude as number) || '');
    };

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(getPosition);
        }
    };

    useEffect(() => {
        getLocation();
    }, []);

    return {
        altitude,
        latitude,
        longitude,
    };
};

export default useGeo;

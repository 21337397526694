const hours = 12;
const now = new Date().getTime();
const setupTime = localStorage.getItem('setupTime');
export const checkSepUpTime = () => {
    if (!setupTime) {
        localStorage.setItem('setupTime', now.toString());
    } else {
        if (new Date().getTime() - Number(setupTime) > hours * 60 * 60 * 1000) {
            localStorage.clear();
            localStorage.setItem('setupTime', now.toString());
        }
    }
};

export const currencyFormat = (val: any): string => {
    return Number(val)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const currencyFormat3Dig = (val: any): string => {
    return Number(val)
        .toFixed(3)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ICardData } from '../../interfaces/modules';
import IconCheckCircle from '../../../images/check-circle.svg';
import IconRemove from '../../../images/remove.svg';
import IconPdf from '../../../images/pdf.svg';
import './card.scss';

interface ICardProp {
    data: ICardData | null;
    idModule: number;
    onClickCard?: Function;
    payload?: any;
}

function Card(props: ICardProp) {
    const [card, setCard] = useState<ICardData | null>();
    const [idModule, setIdModule] = useState<number | null>();
    const setPayload = () => {
        if (props.onClickCard) {
            props.onClickCard(props.payload);
        }
    };

    useEffect(() => {
        setCard(props.data);
        setIdModule(props.idModule);
    }, [props]);

    return (
        <div className="Card">
            <div className="CardLeftTop">
                <img src={IconCheckCircle} alt="" /> {card?.cumplimiento}
            </div>
            <div className="CardRightTop">
                <img src={IconRemove} alt="" /> {card?.incidencias}
            </div>
            <h4
                className="CardTitle"
                style={{ backgroundColor: card?.colordashboard }}
            >
                {card?.nombretienda}
            </h4>
            <div className="CardLeftBottom">
                <Link
                    onClick={setPayload}
                    className="CardLeftBottomLink"
                    to={
                        '/detalle/submodulo/' +
                        idModule +
                        '/' +
                        card?.idcheckevento
                    }
                >
                    Ver más...
                </Link>
            </div>
            <div className="CardRightBottom">
                {card?.urlinforme ? (
                    <a href={card?.urlinforme} target="blank">
                        <img src={IconPdf} alt="PDF" />
                    </a>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
}

export default Card;

import { useContext, useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactDataGrid from 'react-data-grid';
import classnames from 'classnames';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import HttpAxios from '../../../commons/hooks/http';
import Select, { components } from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import { EErrorMsg, ESelectCustom, ESelectTheme } from '../../../commons/statics/controls';
import TablePaged from '../../../commons/components/table/TablePaged';
import { useUserMe } from '../../../commons/hooks/userme';
import { AppContext } from '../../../commons/context/App-context';
import IconExcel from '../../../images/excel.svg';
import { ITiendas } from '../../../commons/interfaces/user';
import { Errors } from '../../../commons/constants/submodules';
import { IParameters } from '../../../commons/interfaces/items';
import enviroment from '../../../enviroments/enviroment';
import Swal from 'sweetalert2'
import es from 'date-fns/locale/es';
import './view69.scss';

registerLocale('es', es);

const defaultOption = {
  value: 0,
  label: 'TODOS',
};

let params: IParameters = {};
let memoParams: IParameters = {};

function View69() {
  const { changeIsLoading, changeSnackBar, changeUrlBackTop, changePayload69, payload69, changeShowModalEdit69 } = useContext(AppContext);
  const history = useHistory();
  const [noData, setNoData] = useState<boolean>(false);
  const [year, setYear] = useState({ value: 2022, label: '2022' });

  const headerColumns = [
    {
      key: 'zona',
      name: 'ZONA',
      width: '5%',
      cellClass: () => {
        return 'colcenter';
      },
    },
    {
      key: 'tienda',
      name: 'TIENDA',
      width: '15%'
    },
    {
      key: 'fecha',
      name: 'FECHA',
      width: '5%',
      cellClass: () => {
        return 'colcenter';
      },
    },
    {
      key: 'nrocorrelativo',
      name: '# CORRELATIVO',
      width: '8%',
      cellClass: () => {
        return 'colcenter';
      },
    },
    {
      key: 'encargadorecepcion',
      name: 'ENCARGADO RECEPCIÓN',
      width: '15%',
      cellClass: () => {
        return 'colcenter';
      },
    },
    {
      key: 'operadorlog',
      name: 'OPERADOR LOGÍSTICO',
      width: '5%',
      cellClass: () => {
        return 'colcenter';
      },
    },
    {
      key: 'horallegadacamion',
      name: 'HORA LLEGADA CAMIÓN',
      width: '5%',
      cellClass: () => {
        return 'colcenter';
      },
    },
    {
      key: 'horainiciodescarga',
      name: 'HORA INICIO DESCARGA',
      width: '5%',
      cellClass: () => {
        return 'colcenter';
      },
    },
    {
      key: 'horafindescarga',
      name: 'HORA FIN DESCARGA',
      width: '5%',
      cellClass: () => {
        return 'colcenter';
      },
    },
    {
      key: 'horaguia',
      name: 'HORA GUÍA REMISIÓN',
      width: '5%',
      cellClass: () => {
        return 'colcenter';
      },
    },
    {
      key: 'tipounidadresumen',
      name: 'UNIDAD RESUMEN',
      width: '5%',
      cellClass: () => {
        return 'colcenter';
      },
    },
    {
      key: 'bultos',
      name: 'BULTOS',
      width: '5%',
      cellClass: () => {
        return 'colcenter';
      },
    },
    {
      key: 'unidades',
      name: 'UNIDADES',
      width: '5%',
      cellClass: () => {
        return 'colcenter';
      },
    },
    {
      key: 'observaciones',
      name: 'OBSERVACIÓN RECEPCIÓN',
      width: '8%',
      cellClass: () => {
        return 'colcenter';
      },
    },
    {
      key: 'estadoatencion',
      name: 'ESTADO',
      width: '5%',
      cellClass: () => {
        return 'colcenter';
      },
    },
  ];
  const [msgError, setMsgError] = useState<string>('');
  const [useMe, setUseMe] = useUserMe();
  const [stores, setStores] = useState<Array<ITiendas> | null>(null);
  const [checklists, setChecklists] = useState([]);
  const [status, setStatus] = useState([]);
  const [lists, setLists] = useState({
    value: 21,
    label: 'Checklist Revisión Visual Tienda',
  });
  const [auditoria, setAuditoria] = useState({
    value: 316,
    label: 'Auditoria Operativa',
  });
  const [checkauditorias, setCheckAuditorias] = useState([]);
  const [jefe, setJefe] = useState({ value: 0, label: 'TODOS' });
  const [checkjefes, setCheckjefes] = useState([]);
  const [years, setYears] = useState<Array<any>>([]);
  const [stateAttention, setStateAttention] = useState(defaultOption);
  const [store, setStore] = useState(defaultOption);
  const [rows, setRows] = useState<Array<any>>([]);
  const [columns, setColumns] = useState(headerColumns);
  const [parameters, setParameters] = useState<IParameters>({});
  const [totalPerPage, setTotalPerPage] = useState(0);
  const [nextPage, setNextPage] = useState(2);
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [initLoading, setInitLoading] = useState<boolean>(false);
  const selectStyles = ESelectCustom;
  const selectTheme = ESelectTheme;
  const [endDate, setEndDate] = useState(new Date());
  const subsDays = dayjs(new Date()).subtract(7, 'day');
  const [startDate, setStartDate] = useState(subsDays.toDate());
  const [fullName, setFullName] = useState('');
  const btnSearchRef = useRef<any>(null);
  const [rowSelected, setRowSelected] = useState(null);
  const [idxSelected, setIdxSelected] = useState(null);
  const [statusSelected, setStatusSelected] = useState([]);

  const getItems = (items: any, hasDefault?: boolean): any => {
    let temp: any = hasDefault ? [] : [defaultOption];

    items?.forEach((s: any) => {
      temp.push({
        value: !isNaN(s.id) ? s.id : !isNaN(s.idsector) ? s.idsector : !isNaN(s.idjefezonal) ? s.idjefezonal : 0,
        label: s.descripcion || s.nombre,
      });
    });

    return temp;
  };

  const getAuditorias = () => {
    HttpAxios.post('/entidad/estados', {
      id: 33,
      idestadopadre: 0,
      todos: false,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || [];
        const options = getItems(items, true);
        setCheckAuditorias(options);
        if (payload69) {
          const elem = options.filter((el: any) => el.value === payload69?.idsector);
          if (elem) {
            onChangeCheckAuditoria(elem[0]);
          }
          searchByBack();
        }
        changePayload69(null);
      })
      .catch(() => {
        changePayload69(null);
      });
  };

  const getJefes = () => {
    HttpAxios.post('/zonas/jefes', {
      todos: true,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || [];
        const options = getItems(items, true);
        setCheckjefes(options);
        if (payload69) {
          const elem = options.filter((el: any) => el.value === payload69?.idjefezonal);
          if (elem) {
            onChangeCheckJefe(elem[0]);
          }
        }
        getAuditorias();
      })
      .catch((err) => {
        getAuditorias();
      });
  };

  const handleExport = () => {
    changeIsLoading(true);

    HttpAxios.post('/incidencias/getcabecera_xls', parameters)
      .then((res: any) => {
        const { err, url, response } = res?.data || {};
        changeIsLoading(false);

        if (err) {
          changeSnackBar(EErrorMsg.generic);
        } else {
          handleDownload(url || response);
        }
      })
      .catch(() => {
        changeIsLoading(false);
        changeSnackBar(EErrorMsg.generic);
      });
  };

  const handleDownload = (urlFile: string = '') => {
    const link = document.createElement('a');
    link.href = urlFile;
    link.setAttribute('download', `${urlFile.split('/')[urlFile.split('/').length - 1]}`);
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      link?.parentNode?.removeChild(link);
    }, 100);
  };

  const search = (page: number = payload69?.page || 0, isPaginated?: boolean) => {
    if (!isPaginated) {
      const rangeStart = startDate ? dayjs(startDate).format(enviroment.search.formatDate) : '';
      const rangeEnd = endDate ? dayjs('' + endDate).format(enviroment.search.formatDate) : '';

      params = {
        idtienda: store?.value || 0,
        idoperadorlog: 0,
        fecha_ini: rangeStart,
        fecha_fin: rangeEnd || rangeStart,
        idestado: stateAttention?.value || 0,
        idjefezonal: jefe?.value || 0,
        limit: enviroment.search.limitPerPage,
      };
      memoParams = params;
    }

    let paramsSearch = params;
    paramsSearch.page = page;
    memoParams.page = page;
    changeIsLoading(true);
    setInitLoading(true);
    setMsgError('');
    setRows([]);

    HttpAxios.post('/incidencias/getcabecera', paramsSearch)
      .then((res: any) => {
        const data = res?.data || {};
        const response = data.response || [];
        if (response.length) {
          const { nextpage = 2, page = 1, total_pages = 0, total_rows = 0 } = data;
          setNoData(false);
          setColumns(headerColumns);
          setRows(response);
          setTotalPerPage(total_rows || response.length);
          setActivePage(page);
          setTotalPages(total_pages);
          setNextPage(nextpage);
          setParameters(memoParams);
          setMsgError('');
        } else {
          setNoData(true);
          setRows([]);
        }

        changeIsLoading(false);
        setInitLoading(false);
      })
      .catch(() => {
        setMsgError(Errors.generic);
        changeIsLoading(false);
        setInitLoading(false);
      });
  };

  const handlePaged = (ind: number) => {
    search(ind, true);
  };

  const onChangeCheckAuditoria = (evt: any) => {
    setAuditoria(evt);
  };

  const onChangeCheckJefe = (evt: any) => {
    setJefe(evt);
  };

  const onChangeStores = (evt: any) => {
    setStore(evt);
  };

  const onChangeRange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const initBackValues = (stores: Array<ITiendas> | null) => {
    if (payload69) {
      const elem: any = (stores || []).filter((el: any) => el.id === payload69?.idtienda);
      if (elem) {
        const el = getItems(elem);
        onChangeStores(el.length === 1 ? el[0] : el[1]);
      }
    }
  };

  const searchByBack = () => {
    if (payload69 && lists && btnSearchRef) {
      btnSearchRef.current.click();
    }
  };

  const rowClick = (obj: any, r: any): void => {
    changePayload69(memoParams);
    localStorage.setItem('payload69', obj?.idtienda.toString());
    const url = '/detalle/submodulo/69/' + obj?.idincidencia;
    history.push(url);
  };

  const newRecepcion = () => {
    if (store?.value) {
      localStorage.setItem('payload69', store?.value.toString());
      changePayload69(memoParams);
      newIncidencia()
    } else {
      Swal.fire('Error', 'Debe seleccionar una tienda para poder crear una nueva incidencia', 'error')
    }
  };

  const newIncidencia = () => {
    const params = {
      idincidencia: 0,
      idtienda: store?.value,
      fecha: dayjs(new Date()).format('YYYYMMDD'),
      encargadorecepcion: '',
      horallegadacamion: '',
      horainiciodescarga: '',
      horafindescarga: '',
      observaciones: '',
      flagactivo: true,
      coordenadas: '120, -123',
      horaguia: '',
      idoperadorlog: 0,
    }

    HttpAxios.post('/incidencias/guardar', params)
      .then((res: any) => {
        const data = res?.data?.response || []
        if(data) {
          const url = '/detalle/submodulo/69/' + data?.idincidencia;
          history.push(url);
        }
      })
      .catch((e: any) => {
        console.log(e)
      })
  }

  const getOptionsYear = () => {
    let years = [];
    const currentYear = new Date().getFullYear();
    years = [...Array.from({ length: currentYear + 1 - (currentYear - 4) }, (_: any, i: number) => i + (currentYear - 4))];
    years = years.map((item: any) => {
      return { value: item, label: item };
    });
    setYears(years);
  };

  useEffect(() => {
    const stores: Array<ITiendas> | null = useMe?.tiendas || null;
    setStores(stores);
    initBackValues(stores);
    getJefes();
    !payload69 && search();
    getOptionsYear();
    changeUrlBackTop('/submodulo/69');
    changeIsLoading(true);
  }, []);

  return (
    <>
      <div className="View69Search">
        <div className="row-6">
          <div className="col">
            <label className="label">Tienda</label>
            <Select
              id="store"
              name="store"
              styles={selectStyles}
              theme={selectTheme}
              value={store}
              isMulti={false}
              hideSelectedOptions={false}
              onChange={(evt) => onChangeStores(evt)}
              placeholder="Selecciona una tienda"
              options={getItems(stores)}
              components={{ ValueContainer }}
            />
          </div>
          <div className="col">
            <label className="label">Jefe Zonal</label>
            <Select id="jefes" name="jefes" styles={selectStyles} theme={selectTheme} value={jefe} onChange={(evt) => onChangeCheckJefe(evt)} placeholder="Selecciona un jefe" options={checkjefes} />
          </div>
          <div className="col">
            <label className="label">Rango de fechas</label>
            <DatePicker onChange={onChangeRange} startDate={startDate} endDate={endDate} selectsRange={true} locale="es" className="input" dateFormat="dd/MM/yyyy" placeholderText="Selecione un rango de fecha" maxDate={new Date()} />
          </div>
          <div className="col">&nbsp;</div>
          <div className="col">
            <label className="label empty">&nbsp;</label>
            <button type="button" onClick={() => search()} className="button buttonFull" ref={btnSearchRef}>
              Buscar
            </button>
          </div>
        </div>
      </div>

      <div className="View69Body">
        {noData || msgError ? (
          !initLoading && (
            <div
              className={classnames({
                noData: noData,
                msgError: msgError,
              })}
            >
              {msgError ? msgError : 'No se encontró resultado en la búsqueda'}
            </div>
          )
        ) : (
          <>
            <div className="View69SearchActions">
              <div className="View69SearchActionsTotal">
                <div>{totalPerPage > 0 && 'Total de registros: ' + totalPerPage}</div>
                <div>
                  <button type="button" onClick={() => newRecepcion()} className="button buttonFull">
                    Nueva Recepción
                  </button>
                </div>
              </div>
              <div className="separate"></div>
              {totalPerPage > 0 && (
                <button className="button buttonExcel" type="button" onClick={handleExport}>
                  <img src={IconExcel} alt="Excel" className="buttonIconLeft" />
                  Exportar
                </button>
              )}
            </div>

            <ReactDataGrid
              className="View69Table"
              columns={columns}
              rows={rows}
              onRowsChange={setRows}
              onRowClick={rowClick}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
            />
            <TablePaged msgError={msgError} activePage={activePage} handlePaged={handlePaged} totalPages={totalPages} />
          </>
        )}
      </div>
    </>
  );
}

const ValueContainer = ({ children, ...props }: any) => {
  let [values, input] = children;
  if (Array.isArray(values)) {
    const val = (i: any) => values[i].props.children;
    const { length } = values;
    switch (length) {
      case 1:
        values = `${val(0)} `;
        break;
      default:
        const otherCount = length - 1;
        values = `${val(0)}+ ${otherCount} `;
        break;
    }
  }
  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

export default View69;
